import React, { useContext } from "react";
import { ShopContext } from "../context/ShopContext";
import Slider from "react-slick";
import ItemCard from "./ItemCard";

function RelatedProducts({ category }) {
  const { all_products } = useContext(ShopContext);

  function ProductSlider({ products, item, heading }) {
    // const shuffledProducts = products.sort(() => Math.random() - 0.5).slice(0, 8);

    // console.log(shuffledProducts);

    // console.log(products);

    const shuffledProducts = products.filter(
      (product) => product.category === item
    );

    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      // speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      // speed: 6000,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      // cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };

    return (
      <div className="container pb-5 border-bottom">
        <div className=" mb-5">
          {/* <div className="heading">
            <p>{tilte}</p>
          </div> */}

          <h2 className="headingh2 text-center">{heading}</h2>
        </div>

        <div className="slider-container">
          <Slider {...settings}>
            {shuffledProducts.map((item, index) => (
              <div key={index}>
                <ItemCard product={item} key={index} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ProductSlider
        products={all_products}
        item={category}
        tilte=""
        heading="Related Products "
      />
    </div>
  );
}

export default RelatedProducts;
