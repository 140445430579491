import React from "react";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <div>
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Contact Us
            </li>
          </ol>
        </nav>
      </div>

      <div className="container text-center pb-lg-5">
        <div className="row">
          <div className="col-lg-6 bg-primary text-white d-flex justify-content-center align-items-center">
            <div className="py-5">
              <h1 className="b9 display-4">Contact Us </h1>
              <p>WhatsApp : +92 310 1052906</p>
              <p>Call: +92 310 1052906 </p>
              <p>Email: ajhouse100@gmail.com</p>
              <p>Address : Golimar Sanitary Market Karachi</p>
            </div>
          </div>

          <div className="col-lg-6 bg-gray">
            <div className="container w-75 py-5">
              <h2>Let’s keep in touch</h2>

              <form>
                <div className="mb-3">
                  <label className="form-label">Your Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Your Email</label>
                  <input type="email" className="form-control" />
                </div>

                <div className="mb-3">
                  <label className="form-label">Contact Number</label>
                  <input type="text" className="form-control" />
                </div>

                <div className="mb-3">
                  <label className="form-label">Subject</label>
                  <input type="text" className="form-control" />
                </div>

                <div className="mb-3">
                  <label className="form-label">Message</label>
                  <textarea type="text" className="form-control" />
                </div>

                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
