import React from "react";
import { Link } from "react-router-dom";
import Shipping from "../components/Shipping";

function About() {
  return (
    <div>
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              About
            </li>
          </ol>
        </nav>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="b7">Our Story</h2>
            <p>
              Welcome to your go-to destination for premium bathroom and kitchen
              products in Pakistan, where quality meets affordability. As
              pioneers in the online home and kitchen market, we've charted a
              course to bring you top-notch products without breaking the bank.
              Our commitment extends beyond just transactions – we're dedicated
              to offering exceptional customer service and friendly support
              throughout your journey with us.
            </p>
            <p>
              At the heart of our mission is prioritizing your satisfaction.
              We've carefully curated a range of high-quality, budget-friendly
              products that cater to your needs. We've covered the distance to
              ensure you receive not just products, but an experience that
              aligns with your expectations.
            </p>
            <p>
              Indulge in a seamless shopping experience with us – where
              excellence, affordability, and customer satisfaction converge.
              Your home and kitchen dreams start here. Shop on! 🛒
            </p>
          </div>

          <div className="col-lg-6">
            <img
              src={require("../assets/images/about/about.jpg")}
              alt="..."
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <Shipping />
    </div>
  );
}

export default About;
