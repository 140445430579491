import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Error from "./components/Error";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsofService from "./pages/TermsofService";
import About from "./pages/About";
import AddProduct from "./pages/AddProduct";
import ProductDetails from "./pages/ProductDetails";

import ShopByCategory from "./pages/ShopByCategory";
import ShopByBrandCategory from "./pages/ShopByBrandCategory";

import Products from "./pages/Products";
import Contact from "./pages/Contact";
// import SignIn from "./pages/SignIn";
// import SignUp from "./pages/SignUp";

function WAButton() {
  return (
    <div className="whatsapp-popup">
      <Link
        to="https://api.whatsapp.com/send/?phone=+923101052906&text=Hello there, I am interested in collaborating with you."
        target="_blank"
        rel="noreferrer"
        className="whatsapp-link"
      >
        <i className="bi bi-whatsapp"></i>
      </Link>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/sanitary"
          element={<ShopByCategory category="sanitary" />}
        />

        <Route
          path="/electric"
          element={<ShopByCategory category="electric" />}
        />

        <Route
          path="/:category/:subCategory"
          element={<ShopByBrandCategory />}
        />
        {/* <Route
          path="/:category/:brand"
          element={<ShopByBrandCategory category="Laptop" />}
        /> */}

        <Route
          path="/:category/:subCategory/:productId"
          element={<ProductDetails />}
        />

        {/* <Route path="/product/:productId" element={<ProductDetails />} /> */}

        <Route path="/add-product" element={<AddProduct />} />
        {/* <Route path="/view-product" element={<ViewProducts />} />
        <Route path="/edit-product" element={<EditProduct />} /> */}
        <Route path="/product" element={<Products />} />
        <Route path="/product/:productId" element={<ProductDetails />} />

        {/* <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} /> */}
        {/* <Route path="/services" element={<Services />} /> */}
        {/* <Route path="/contact" element={<Contact />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsofService />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <WAButton />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
