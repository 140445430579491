import React, { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";

import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../db/fbConfig";
import { numberWithCommas } from "../hooks/NumberWithComma";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

function AddProduct() {
  const editor = useRef(null);
  const [specs, setSpecs] = useState("");
  const [desc, setDesc] = useState("");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [productType, setProductType] = useState("");

  useEffect(() => {
    const modifiedString = url.replace(/\s/g, "").replace(/\|/g, "-");
    setUrl(modifiedString);
    // console.log(modifiedString);
  }, [url]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const outputArray = Object.values(files);
    setSelectedFiles(outputArray);
  };

  const addNewProduct = async (e) => {
    e.preventDefault();
    try {
      const uploadPromises = selectedFiles.map(uploadImages);
      const urls = await Promise.all(uploadPromises);

      const updatedImages = urls.map((url, index) => ({
        id: index,
        url: url,
        alt: "...",
      }));

      await addDoc(collection(db, "allProducts"), {
        created: serverTimestamp(),
        name: title,
        price: price,
        category: category,
        subCategory: subCategory,
        productType: productType,
        images: updatedImages,
        specification: specs,
        description: desc,
        url: url,
        isActive: true,
        ratings: 5,
        reviews: [],
      });
      console.log("Document written with ID:");
    } catch (error) {
      console.error("Error adding new product:", error);
    }
  };

  const uploadImages = async (_images) => {
    try {
      const fileRef = ref(
        storage,
        `product/${category}/${subCategory}/${url}/${_images.name}`
      );
      const uploadTask = uploadBytesResumable(fileRef, _images);

      const uploadResult = await uploadTask;

      if (uploadResult) {
        const url = await getDownloadURL(fileRef);
        return url;
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error; // Rethrow the error to handle it at the higher level if needed
    }
  };

  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <h1>Add New Product</h1>

            <form onSubmit={addNewProduct}>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Product Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Product URL
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="url"
                  placeholder="URL"
                  onChange={(e) => setUrl(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="price" className="form-label">
                  Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="price"
                  placeholder="Price"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="category" className="form-label">
                  Select Category
                </label>
                <select
                  className="form-select"
                  id="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  <option value="sanitary">Sanitary</option>
                  <option value="electric">Electric</option>
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="category" className="form-label">
                  Select Sub Category
                </label>
                <select
                  className="form-select"
                  id="subCategory"
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  <option value="" disabled>
                    Select a Sub Category
                  </option>
                  <option value="bathroom sanitary fitting">
                    Bathroom Sanitary Fitting
                  </option>
                  <option value="kitchen sink faucets">
                    Kitchen Sink / Faucets
                  </option>
                  <option value="pipes and fittings">Pipes and Fittings</option>
                  <option value="Wires and cables">Wires and Cables</option>
                  <option value="lights">Lights</option>
                  <option value="electrical accessories">
                    Electrical Accessories
                  </option>
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="type" className="form-label">
                  Product Type
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="type"
                  placeholder="Product Type"
                  onChange={(e) => setProductType(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="spacification" className="form-label">
                  Spacification
                </label>
                <JoditEditor
                  ref={editor}
                  value={specs}
                  // config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setSpecs(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => setSpecs(newContent)}
                />
              </div>

              {/* <div className="mb-3">
                <label htmlFor="img1" className="form-label">
                  Image 1
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="img1"
                  placeholder="Image Link"
                  onChange={(e) => handleImageChange(0, e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="img2" className="form-label">
                  Image 2
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="img2"
                  placeholder="Image Link"
                  onChange={(e) => handleImageChange(1, e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="img3" className="form-label">
                  Image 3
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="img3"
                  placeholder="Image Link"
                  onChange={(e) => handleImageChange(2, e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="img4" className="form-label">
                  Image 4
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="img4"
                  placeholder="Image Link"
                  onChange={(e) => handleImageChange(3, e.target.value)}
                />
              </div> */}

              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <JoditEditor
                  ref={editor}
                  value={desc}
                  // config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setDesc(newContent)}
                  onChange={(newContent) => setDesc(newContent)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="img4" className="form-label">
                  Select Images
                </label>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileChange}
                />
              </div>

              <div className="mb-3">
                <input type="submit" className="form-control btn btn-danger" />
              </div>
            </form>
          </div>

          <div className="col-lg-7">
            <h3> {title}</h3>
            <h4>Price: Rs. {numberWithCommas(price)} </h4>
            <h5>URL: {url} </h5>
            <h5>Category: {category}</h5>
            <h5>Sub Category: {subCategory}</h5>
            <h5>Type: {productType}</h5>
            {/* <div className="row">
              {images.length > 0 && (

                {images.map((img)=> {
<div className="col-lg-3">
                  <img src={img.url} className="img-fluid" />
                </div>
                })}
                
                
              )}
            </div> */}

            <h4>Specification</h4>
            <div dangerouslySetInnerHTML={{ __html: specs }} />

            <h4>Description</h4>
            <div dangerouslySetInnerHTML={{ __html: desc }} />

            <div className="row">
              {selectedFiles.length > 0 &&
                selectedFiles.map((img, index) => (
                  <div key={index} className="col-lg-3">
                    <img
                      src={URL.createObjectURL(img)}
                      alt={img.name}
                      className="img-fluid"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
