import React from "react";

function Shipping() {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-4">
          <div className="shipping">
            <div className="shipIcon">
              <i className="bi bi-truck"></i>
            </div>
            <h5> Fast Delivery</h5>
            <small>Fast delivery all over the country </small>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="shipping">
            <div className="shipIcon">
              <i className="bi bi-headphones"></i>
            </div>
            <h5>24/7 Customer Service</h5>
            <small>Friendly 24/7 customer support</small>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="shipping">
            <div className="shipIcon">
              <i className="bi bi-shield-check"></i>
            </div>
            <h5>Money Back Guarantee</h5>
            <p>Exchange product within 7 days</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shipping;
