import React, { useContext, useEffect, useState } from "react";
import { ShopContext } from "../context/ShopContext";
import ItemCard from "../components/ItemCard";

function Products() {
  const { all_products } = useContext(ShopContext);

  const [selectedSortOption, setSelectedSortOption] = useState(1);
  const [products, setProducts] = useState(all_products);

  useEffect(() => {
    setProducts(all_products);
  }, [all_products]);

  const handleSortChange = (event) => {
    setSelectedSortOption(event.target.value);
    filterProducts(event.target.value);
  };

  const filterProducts = (option) => {
    let sortedProducts = [...all_products];

    if (option === "1") {
      sortedProducts.sort((a, b) => a.price - b.price);
    } else if (option === "2") {
      sortedProducts.sort((a, b) => b.price - a.price);
    } else if (option === "3") {
      // Sort by latest logic (you may need a different sorting mechanism)
      // sortedProducts.sort(...);
    }

    setProducts(sortedProducts);
  };

  return (
    <div>
      <div className="container py-4">
        <div className="row">
          <div className="col-lg-8">
            <div className="heading">
              <p>Our Products</p>
            </div>
            <h2 className="headingh2">Explore Our Products</h2>
          </div>

          <div className="col-lg-4  d-flex justify-content-end align align-items-center">
            <select
              value={selectedSortOption}
              onChange={handleSortChange}
              className="form-select"
              aria-label="Default select example"
            >
              <option value="1" defaultChecked>
                Sort by price: Low to High
              </option>
              <option value="2">Sort by price: High to Low</option>

              <option value="3">Sort by latest</option>
            </select>
          </div>
        </div>
      </div>

      <div className="container productList">
        <div className="row">
          {products.map((item, index) => {
            return (
              <div
                key={index}
                className="col-lg-3 col-md-4 col-sm-6 col-xs-6 col-6"
              >
                <ItemCard product={item} key={index} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Products;
