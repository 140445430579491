import React from "react";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <div className="container py-5 w-75">
      <h2 className="headingh2 text-center">Privacy Policy</h2>

      <strong>Last updated December 1, 2023</strong>
      <br />
      <br />
      <p>
        Thank you for choosing to engage with Device Bazaar ("Company," "we,"
        "us," or "our"). We value your privacy and are committed to safeguarding
        your personal information. Should you have any inquiries or concerns
        about this privacy notice or our practices regarding your personal
        information, please contact us at{" "}
        <a href="mailto:info@devicebazaar.pk">info@devicebazaar.pk</a>.
      </p>
      <p>
        This privacy notice outlines how we may collect and utilize your
        information when you:
      </p>

      <ul>
        <li>
          Visit our website at
          <Link target="_blank" to="www.devicebazaar.pk">
            www.devicebazaar.pk
          </Link>
        </li>
        <li>Interact with us through sales, marketing, or events</li>
      </ul>

      <h4>TABLE OF CONTENTS</h4>

      <ol>
        <li>INFORMATION COLLECTION</li>
        <li>HOW WE USE YOUR INFORMATION</li>
        <li>SHARING YOUR INFORMATION</li>
        <li>DATA RETENTION</li>
        <li>SECURITY OF YOUR INFORMATION</li>
        <li>CHILDREN'S INFORMATION</li>
        <li>YOUR PRIVACY RIGHTS</li>
        <li>CHANGES TO THIS NOTICE</li>
        <li>CONTACT US</li>
      </ol>

      <h4>1. INFORMATION COLLECTION</h4>
      <p>
        <strong> Personal Information You Provide: </strong>
        <br />
        <br /> We collect personal information that you voluntarily provide when
        you interact with our website, express interest in our products or
        services, or engage in activities with us.
        <br />
        This may include:
      </p>

      <ul>
        <li>Names, phone numbers, email addresses</li>
        <li>Mailing addresses, usernames, passwords</li>
        <li>Contact preferences, billing addresses, payment details</li>
        <li>Other information you choose to provide</li>
      </ul>

      <p>All information must be accurate and updated accordingly.</p>
      <strong>Automatically Collected Information:</strong>
      <br />
      <br />
      <p>
        When you visit our website, certain information, such as IP addresses,
        browser characteristics, and device details, is automatically collected.
        This data is essential for maintaining website security, internal
        analytics, and operational purposes.
      </p>

      <h4>2. HOW WE USE YOUR INFORMATION</h4>
      <p>
        We process your information for legitimate business interests, contract
        fulfillment, legal compliance, or with your consent. The purposes
        include but are not limited to:
      </p>
      <ul>
        <li>Account creation and management</li>

        <li>Communication with users</li>
        <li>Service delivery and support</li>
        <li>Marketing and promotional activities</li>
        <li>Customized content delivery</li>
        <li>Legal compliance and enforcement</li>
      </ul>

      <h4>3. SHARING YOUR INFORMATION</h4>
      <p>
        We only share your information when necessary, based on legal
        obligations, legitimate interests, or with your explicit consent. This
        may include sharing with:
      </p>

      <ul>
        <li>Service providers and vendors</li>
        <li>Legal entities, authorities, or for compliance</li>
        <li>In connection with business transfers</li>
      </ul>

      <h4>4. DATA RETENTION</h4>
      <p>
        We retain personal information for as long as needed to fulfill the
        outlined purposes unless a longer retention period is required by law.
      </p>

      <h4>5. SECURITY OF YOUR INFORMATION</h4>
      <p>
        We employ organizational and technical security measures to protect your
        information. However, we cannot guarantee absolute security against
        unauthorized access or breaches.
      </p>

      <h4>6. CHILDREN'S INFORMATION</h4>
      <p>
        We do not knowingly collect or market to children under 18. If we
        discover such data, we take immediate steps to remove it.
      </p>
      <h4>7. YOUR PRIVACY RIGHTS</h4>
      <p>
        You have the right to review, update, or delete your information.
        Contact us to exercise these rights or if you have privacy-related
        queries.
      </p>
      <h4>8. CHANGES TO THIS NOTICE</h4>
      <p>
        We reserve the right to update this privacy notice as necessary.
        Significant changes will be communicated through prominent notices on
        our website.
      </p>
      <h4>9. CONTACT US</h4>
      <p>
        For any inquiries or concerns regarding this notice, please contact us
        at info@devicebazaar.pk.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
