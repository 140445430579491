import React, { useContext } from "react";
import { ShopContext } from "../context/ShopContext";
import ItemCard from "../components/ItemCard";
import { Link, useParams } from "react-router-dom";

function ShopByBrandCategory() {
  const { all_products } = useContext(ShopContext);

  const { category, subCategory } = useParams();

  const brandCategoryProducts = all_products.filter(
    (product) =>
      product.category.toLowerCase() === category.toLowerCase() &&
      product.subCategory.toLowerCase() === subCategory.toLocaleLowerCase()
  );

  return (
    <div>
      <div className="container pt-4">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/" className="text-uppercase">
                Home
              </Link>
            </li>
            {/* <li className="breadcrumb-item" aria-current="page">
              <Link to="/">Products</Link>
            </li> */}

            <li className="breadcrumb-item active" aria-current="page">
              <Link to={`/${category}`} className="text-uppercase">
                {category}
              </Link>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              <Link
                to={`/${category}/${subCategory}`}
                className="text-uppercase"
              >
                {subCategory}
              </Link>
            </li>
          </ol>
        </nav>
      </div>

      <div className="container">
        <div className="row">
          {brandCategoryProducts.map((item, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6">
              <ItemCard product={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ShopByBrandCategory;
