import React, { useContext, useLayoutEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { numberWithCommas } from "../hooks/NumberWithComma";
import { ShopContext } from "../context/ShopContext";
import Error from "../components/Error";
import { shortenTitle } from "../hooks/ShortenTitle";
import Loading from "../components/Loading";
import RelatedProducts from "../components/RelatedProducts";
// import RelatedProducts from "../components/RelatedProducts";

function ProductDetails() {
  const { all_products } = useContext(ShopContext);
  const [product, setProduct] = useState(null);

  const currentURL = window.location.href;

  // console.log(product.category);

  const { productId } = useParams();

  useLayoutEffect(() => {
    const fetchProduct = async () => {
      try {
        const filteredProduct = all_products.find(
          (product) => product.url === productId
        );
        // Simulate a delay to show a loading state
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // If filteredProduct is undefined, set product to null
        setProduct(filteredProduct !== undefined ? filteredProduct : null);
      } catch (error) {
        console.error("Error fetching product:", error);
        setProduct(null);
      }
    };

    fetchProduct();
  }, [all_products, productId]);

  if (product === null) {
    // Show loading state here
    return <Loading />;
  }

  if (product === undefined) {
    // Handle the case where the product is not found
    return <Error />;
  }

  return (
    <div className="container">
      <div className="container pt-4">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/" className="text-uppercase">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item text-uppercase" aria-current="page">
              <Link to={`/${product.category}/`}> {product.category}</Link>
            </li>

            <li className="breadcrumb-item text-uppercase" aria-current="page">
              <Link to={`/${product.category}/${product.subCategory}`}>
                {product.subCategory}
              </Link>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              {shortenTitle(product.name)}
            </li>
          </ol>
        </nav>
      </div>

      <div className="container pb-5">
        <div className="row">
          <div className="col-lg-7">
            {/* <div className="d-flex flex-row">
              {product.images.map((item, index) => {
                return <img src={item.url} alt={item.alt} className="p-img" />;
              })}
            </div> */}

            {/* <img src={product.images[0].url} className="p-img" /> */}

            <div className="row">
              <div className="col-lg-12">
                {/* <img src={product.images[0].url} className="img-fluid" /> */}
                <div
                  id="productImgcarousel"
                  className="carousel slide carousel-dark"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    {product.images.map((image, index) => (
                      <div
                        key={index}
                        className={`carousel-item ${
                          index === 0 ? "active" : ""
                        }`}
                      >
                        <img
                          src={image.url}
                          className="d-block w-100"
                          alt={image.alt}
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#productImgcarousel"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#productImgcarousel"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              {/* <div className="col-lg-12 pimglist">
                {product.images.map((item, index) => {
                  return (
                    <img
                      src={item.url}
                      alt={item.alt}
                      className="img-fluid p-img"
                    />
                  );
                })}
              </div> */}
            </div>
          </div>
          <div className="col-lg-5">
            <div className="mt-3">
              <h5 className="b6">{product.name}</h5>
              <div className="d-flex">
                <div>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                </div>
                <div className="ms-3"> In Stock</div>
              </div>

              <h3 className="b7 mt-2 text-danger">
                Rs. {numberWithCommas(product.price - 0)}
              </h3>
              <div
                dangerouslySetInnerHTML={{ __html: product.specification }}
              />

              <Link
                to={`https://api.whatsapp.com/send/?phone=+923101052906&text=${`Hi, I want to buy this product. 
                %0aTitle : *_${encodeURIComponent(product.name)}_*
                %0aWebsite: ${encodeURIComponent(currentURL)}
                %0aPrice:  *Rs. ${numberWithCommas(product.price)}*
                %0a%0aThank you
              `}`}
                className="btn btn-success mt-3"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-whatsapp"></i>{" "}
                <span className="px-3">ORDER NOW</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <h1>Description</h1>
        <div dangerouslySetInnerHTML={{ __html: product.description }} />
      </div>

      <div>{/* <RelatedProducts category={product.category} /> */}</div>

      <div>
        <RelatedProducts category={product.category} />
      </div>
    </div>
  );
}

export default ProductDetails;
