import React from "react";
import { Link } from "react-router-dom";
// import { ShopContext } from "../context/ShopContext";

function Navbar() {
  // const { all_products } = useContext(ShopContext);

  // const [searchQuery, setSearchQuery] = useState("");
  // const [searchResults, setSearchResults] = useState([]);

  // const handleSearchChange = (e) => {
  //   const query = e.target.value.toLowerCase();
  //   setSearchQuery(query);

  //   // Filter products based on the search query
  //   const filteredProducts = all_products.filter(
  //     (product) =>
  //       product.name.toLowerCase().includes(query) ||
  //       product.description.toLowerCase().includes(query)
  //   );

  //   setSearchResults(filteredProducts);
  // };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <Link className="navbar-brand" to={`/`}>
          <img
            src={require("../assets/dblogo.png")}
            alt="Bootstrap"
            // width="289"
            height="80"
          />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          style={{ width: "75%" }}
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              <img
                src={require("../assets/dblogo.png")}
                alt="Bootstrap"
                width="95%"
                height="80"
              />
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-center flex-grow-1 pe-3 text-center">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to={`/`}>
                  Home
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to={`/`}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Sanitary
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      to={`/sanitary/bathroom%20sanitary%20fitting`}
                    >
                      Bathroom Sanitary Fitting
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="dropdown-item"
                      to={`/sanitary/kitchen%20sink%20faucets`}
                    >
                      Kitchen Sink / Faucets
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to={`/`}>
                      Pipes and Fittings
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to={`/`}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Electrical
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      to={`/electric/Wires%20and%20cables`}
                    >
                      Wires and Cables
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to={`/electric/lights`}>
                      Lights
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="dropdown-item"
                      to={`/electric/electrical%20accessories`}
                    >
                      Electrical Accessories
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link className="nav-link" to="/add-product">
                  Add Products
                </Link>
              </li> */}
            </ul>
            <form className="d-flex custom-search-form" role="search">
              <input
                className="form-control rounded-start"
                type="search"
                placeholder="What are you looking for?"
                aria-label="Search"
                // value={searchQuery}
                // onChange={handleSearchChange}
              />
              <button
                className="btn btn-outline-success rounded-end"
                type="submit"
              >
                <i className="bi bi-search"></i>
              </button>
            </form>

            {/* <section className="search_section">
              <div className="search_input_div">
                <input
                  className="form-control rounded-start search_input"
                  type="text"
                  placeholder="Search..."
                  autoComplete="off"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <div className="search_icon">
                  <SearchIcon />
                  <CloseIcon />
                </div>
              </div>
              <div className="search_result">
                {searchResults.map((product, index) => (
                  <Link
                    key={index}
                    className="search_suggestion_line"
                    to={`/product/${product.url}`}
                  >
                    {product.name}
                  </Link>
                ))}
              </div>
            </section> */}

            {/* {searchResults.length > 0 && (
              <div className="search-overlay">
                <div className="search-results">
                  <h4>Search Results:</h4>
                  <ul>
                    {searchResults.map((product) => (
                      <li key={product.id}>
                        <Link to={`/product/${product.url}`}>
                          {product.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )} */}

            {/* <div className="d-flex align-items-center justify-content-center navicon">
              <Link to="/">
                <i className="bi bi-heart"></i>
              </Link>
              <Link to="/">
                <i className="bi bi-cart3"></i>
              </Link>
              <Link to="/sign-in">
                <i className="bi bi-person-circle"></i>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
