import React from "react";
import { Link } from "react-router-dom";

function Error() {
  return (
    <div>
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              404 Error
            </li>
          </ol>
        </nav>
      </div>

      <div className="container py-5 d-flex flex-column align-items-center mb-5 text-center">
        <h1 className="display-1 b9">404 Not Found!</h1>
        <p>Your visiting page not found. You may go home page.</p>
        <div>
          <Link to="/" className="btn btn-success">
            Back to home page
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Error;
