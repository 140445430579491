import React, { useContext } from "react";
import Shipping from "../components/Shipping";
import { Link } from "react-router-dom";
import ItemCard from "../components/ItemCard";
import { ShopContext } from "../context/ShopContext";
import { windowScroll } from "../hooks/WindowScroll";

function Slider() {
  return (
    <div id="carouselExampleIndicators" className="carousel slide">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>

        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src={require("../assets/images/sl4.png")}
            className="d-block w-100"
            alt="..."
          />
        </div>
        <div className="carousel-item">
          <img
            src={require("../assets/images/sl2.png")}
            className="d-block w-100"
            alt="..."
          />
        </div>
        <div className="carousel-item">
          <img
            src={require("../assets/images/sl4.png")}
            className="d-block w-100"
            alt="..."
          />
        </div>
        <div className="carousel-item">
          <img
            src={require("../assets/images/sl2.png")}
            className="d-block w-100"
            alt="..."
          />
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}

function CardSection() {
  return (
    <div className="container py-5 border-bottom">
      <div className="row">
        <div className="col-lg-4 col-12">
          <Link
            to="/sanitary/bathroom%20sanitary%20fitting"
            onClick={windowScroll}
          >
            <div className="image-container">
              <img src={require("../assets/images/bat.png")} alt="..." />
              <div className="text-overlay">
                <h1 className="text-uppercase">Bathroom Sanitary Fittings</h1>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-4 col-12">
          <Link to="/sanitary/kitchen%20sink%20faucets" onClick={windowScroll}>
            <div className="image-container">
              <img src={require("../assets/images/kitch.png")} alt="..." />
              <div className="text-overlay">
                <h1 className="text-uppercase">Kitchen Sink / Faucet</h1>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-4 col-12">
          <Link to="/" onClick={windowScroll}>
            <div className="image-container">
              <img src={require("../assets/images/pipe.png")} alt="..." />
              <div className="text-overlay">
                <h1 className="text-uppercase">Pipes and Fittings</h1>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-4 col-12">
          <Link to="/electric/Wires%20and%20cables" onClick={windowScroll}>
            <div className="image-container">
              <img src={require("../assets/images/wire.png")} alt="..." />
              <div className="text-overlay">
                <h1 className="text-uppercase">Wires and cables</h1>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-4 col-12">
          <Link to="/electric/lights" onClick={windowScroll}>
            <div className="image-container">
              <img src={require("../assets/images/light.png")} alt="..." />
              <div className="text-overlay">
                <h1 className="text-uppercase">Lights</h1>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-4 col-12">
          <Link to="/electric/electrical%20accessories" onClick={windowScroll}>
            <div className="image-container">
              <img src={require("../assets/images/electric.png")} alt="..." />
              <div className="text-overlay">
                <h1 className="text-uppercase">Electrical Accessories</h1>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

// function Categories() {
//   return (
//     <div className="container py-5 border-bottom">
//       <div className="py-4">
//         <div className="heading">
//           <p>Categories</p>
//         </div>

//         <h2 className="headingh2">I’M LOOKING FOR..</h2>
//       </div>

//       <div className="row">
//         <div className="col-lg-2 col-md-3 col-sm-4 col-4">
//           <Link to="/" className="cat-card" onClick={windowScroll}>
//             {/* <i className="bi bi-phone"></i> */}
//             <img
//               src={require("../assets/images/category/sel.png")}
//               className="img-fluid"
//               alt="..."
//             />
//             <p>Best Selling</p>
//           </Link>
//         </div>

//         <div className="col-lg-2 col-md-3 col-sm-4 col-4">
//           <Link to="/" className="cat-card" onClick={windowScroll}>
//             {/* <i className="bi bi-laptop"></i>
//             <p>Laptop</p> */}{" "}
//             <img
//               src={require("../assets/images/category/bath.png")}
//               className="img-fluid"
//               alt="..."
//             />
//             <p>Bath Faucets</p>
//           </Link>
//         </div>

//         <div className="col-lg-2 col-md-3 col-sm-4 col-4">
//           <Link to="/" className="cat-card" onClick={windowScroll}>
//             {/* <i className="bi bi-tablet"></i> */}
//             <img
//               src={require("../assets/images/category/soap.png")}
//               className="img-fluid"
//               alt="..."
//             />
//             <p>Soap Dispensers</p>
//           </Link>
//         </div>

//         <div className="col-lg-2 col-md-3 col-sm-4 col-4">
//           <Link to="/" className="cat-card" onClick={windowScroll}>
//             {/* <i className="bi bi-smartwatch"></i> */}
//             <img
//               src={require("../assets/images/category/pulm.png")}
//               className="img-fluid"
//               alt="..."
//             />
//             <p>Plumbing</p>
//           </Link>
//         </div>

//         <div className="col-lg-2 col-md-3 col-sm-4 col-4">
//           <Link to="/" className="cat-card" onClick={windowScroll}>
//             {/* <i className="bi bi-earbuds"></i> */}
//             <img
//               src={require("../assets/images/category/kitchen.png")}
//               className="img-fluid"
//               alt="..."
//             />
//             <p>Kitchen Faucets</p>
//           </Link>
//         </div>

//         <div className="col-lg-2 col-md-3 col-sm-4 col-4">
//           <Link to="/" className="cat-card" onClick={windowScroll}>
//             {/* <i className="bi bi-speaker"></i> */}
//             <img
//               src={require("../assets/images/category/shower.png")}
//               className="img-fluid"
//               alt="..."
//             />
//             <p>Showers</p>
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// }

function Featured() {
  return (
    <div className="container py-5">
      {/* <div className="py-4">
        <div className="heading">
          <p>Featured</p>
        </div>

        <h2 className="headingh2">New Arrival</h2>
      </div> */}

      <div className="row featured">
        <div className="col-lg-6 d-flex mt-4">
          <img
            src={require("../assets/images/featured/4.webp")}
            alt="..."
            className="img-fluid"
          />
        </div>

        <div className="col-lg-6 mt-4">
          <div className="row">
            <div className="col-lg-12">
              <img
                src={require("../assets/images/featured/5.webp")}
                alt="..."
                className="img-fluid"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 d-flex mt-4">
              <img
                src={require("../assets/images/featured/3.webp")}
                alt="..."
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 d-flex mt-4">
              <img
                src={require("../assets/images/featured/1.webp")}
                alt="..."
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Banner() {
  return (
    <div className="container">
      <Link to="/gadget" onClick={windowScroll}>
        <img
          src={require("../assets/images/banner/banner.png")}
          className="img-fluid"
          alt="..."
        />
      </Link>
    </div>
  );
}

function Products({ product }) {
  const shuffledProducts = product.sort(() => Math.random() - 0.5).slice(0, 8);

  return (
    <div className="container py-5 border-bottom ">
      <div className="py-4">
        <div className="heading">
          <p>Our Products</p>
        </div>

        <h2 className="headingh2">Explore Our Products</h2>
      </div>

      <div className="container productList">
        <div className="row">
          {shuffledProducts.map((item, index) => {
            return (
              <div
                key={index}
                className="col-lg-3 col-md-4 col-sm-6 col-xs-6 col-6"
              >
                <ItemCard product={item} key={index} />
              </div>
            );
          })}
        </div>
      </div>

      <div className="d-flex justify-content-center my-4">
        <Link to="/product" className="btn btn-primary" onClick={windowScroll}>
          Load More
        </Link>
      </div>
    </div>
  );
}

function Home() {
  // const [product, setProduct] = useState([]);

  // console.log(product);

  // useLayoutEffect(() => {
  //   const getUsersData = async () => {
  //     // Create Collection Reffrance
  //     const orderRef = collection(db, "Products");

  //     // Create a query against the collection.
  //     const q = query(
  //       orderRef
  //       // where("uid", "==", auth.currentUser.uid)
  //       // orderBy("orderDate", "desc"),
  //       // limit(20)
  //     );

  //     // Get Collection Query Data
  //     const querySnapshot = await getDocs(q);

  //     let product = [];
  //     querySnapshot.forEach((doc) => {
  //       const docId = doc.id;
  //       const data = doc.data();
  //       data["docId"] = docId;
  //       product.push(data);
  //     });

  //     setProduct(product);
  //   };
  //   getUsersData();
  // }, []);

  const { all_products } = useContext(ShopContext);

  // console.log(all_products);

  // const all_products = [];

  return (
    <div>
      {/* Slider Images  */}
      <Slider />

      {/* Card Section  */}
      <CardSection />

      {/* Categories */}
      {/* <Categories /> */}

      {/* Banner Area  */}

      <Banner />

      {/* chromebook */}
      <Products product={all_products} />

      {/* Featured Products */}
      <Featured />

      {/* Shipping  */}
      <Shipping />
    </div>
  );
}

export default Home;
