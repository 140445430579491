// import { DATA } from "../Actions/Action";

const Reducer = (state, action) => {
  switch (action.type) {
    //   case DELETE_TRANS:
    //     return {
    //       ...state,
    //       transactions: state.transactions.filter(
    //         (transaction) => transaction.id !== action.payload
    //       ),
    //     };

    //   case TOKEN_BALANCE:
    //     return {
    //       ...state,
    //       tokenBalance: [action.payload, ...state.tokenBalance],
    //     };

    case "SET_PRODUCTS":
      return {
        ...state,
        all_products: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;
