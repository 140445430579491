import React, { createContext, useEffect, useReducer } from "react";
import Reducer from "./Reducer";
import { db } from "../db/fbConfig";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

export const ShopContext = createContext(null);

// Initial state
const initialState = {
  all_products: [],
};

const ShopContextProvider = (props) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  useEffect(() => {
    const fetchActiveProducts = async () => {
      try {
        const productsCollection = collection(db, "allProducts");
        const activeProductsQuery = query(
          productsCollection,
          where("isActive", "==", true)
        );

        // Set up a snapshot listener to get real-time updates for active products
        const unsubscribe = onSnapshot(activeProductsQuery, (querySnapshot) => {
          const activeProductsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setAllProducts(activeProductsData);
        });

        // Return a cleanup function to unsubscribe when the component unmounts
        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching active products:", error);
      }
    };

    fetchActiveProducts();
  }, []);

  function setAllProducts(_product) {
    dispatch({
      type: "SET_PRODUCTS",
      payload: _product,
    });
  }

  return (
    <ShopContext.Provider
      value={{
        all_products: state.all_products,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
