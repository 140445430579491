import React, { useState } from "react";
import { Link } from "react-router-dom";
import { numberWithCommas } from "../hooks/NumberWithComma";
import { windowScroll } from "../hooks/WindowScroll";

function ItemCard({ product }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      to={`/product/${product.url}`}
      state={{ product }}
      className="productList"
      onClick={windowScroll}
    >
      <div
        className={`card ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          // src={isHovered ? product.images[1].url : product.images[0].url}
          src={product.images[0].url}
          className="card-img-top"
          alt="..."
        />
        <div className="card-body">
          <h5 className="card-title">{product.productType}</h5>
          <p className="card-text">{product.name}</p>

          <div className="d-flex justify-content-center align-items-center flex-column">
            <p className="price" style={{ lineHeight: "0" }}>
              <span className="text-info mr-3">
                Rs. {numberWithCommas(product.price)}
              </span>
            </p>
            <div>
              <i className="bi bi-star-fill"></i>
              <i className="bi bi-star-fill"></i>
              <i className="bi bi-star-fill"></i>
              <i className="bi bi-star-fill"></i>
              <i className="bi bi-star-fill"></i>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ItemCard;
