import React from "react";
import { Link } from "react-router-dom";
// import { windowScroll } from "../hooks/WindowScroll";

function Footer() {
  return (
    <div className="bg-black text-white foot">
      <div className="container py-5 w-75">
        <div className="row">
          <div className="col-lg-3">
            <h5 className="b8">CUSTOMER CARE</h5>

            <div>
              <p>
                <a href="/" rel="noreferrer">
                  FAQ
                </a>
              </p>

              <p>
                <a href="/" rel="noreferrer">
                  ABOUT US
                </a>
              </p>

              <p>
                <a href="/" rel="noreferrer">
                  BEST SELLERS
                </a>
              </p>
            </div>
          </div>

          <div className="col-lg-3">
            <h5 className="b8">OUR POLICIES</h5>

            <div>
              <p>
                <a href="/" rel="noreferrer">
                  PRIVACY POLICY
                </a>
              </p>

              <p>
                <a href="/" rel="noreferrer">
                  REFUND POLICY
                </a>
              </p>

              <p>
                <a href="/" rel="noreferrer">
                  SHIPPING POLICY
                </a>
              </p>

              <p>
                <a href="/" rel="noreferrer">
                  TERMS OF SERVICES
                </a>
              </p>
            </div>
          </div>

          <div className="col-lg-3">
            <h5 className="b8">CONTACT DETAILS</h5>

            <div>
              <p>
                <strong>Location: </strong> Gulbahar Sanitary Market, Karachi,
                Pakistan
              </p>

              <p>
                <strong>Contact: </strong> +92 310 1052906
              </p>

              <p>
                <strong>Email: </strong> ajhouse100@gmail.com
              </p>
            </div>
          </div>

          <div className="col-lg-3">
            <h5 className="b8">SOCIAL MEDIA</h5>

            <div>
              <div className="h5 d-flex justify-content-around">
                <Link
                  to="https://www.facebook.com/profile.php?id=100054371464641"
                  target="_blank"
                >
                  <i className="bi bi-facebook"></i>
                </Link>

                <Link to="https://www.instagram.com" target="_blank">
                  <i className="bi bi-instagram"></i>
                </Link>

                {/* <Link to="/">
                  <i className="bi bi-youtube"></i>
                </Link> */}

                <Link to="https://wa.me/923101052906" target="_blank">
                  <i className="bi bi-whatsapp"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container pb-5 custom-container">
        <h3 className="top-categories-heading">TOP CATEGORIES</h3>

        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 col-4 text-center">
            <Link to="/phone" onClick={windowScroll}>
              <i className="bi bi-phone foot-icon"></i>
              <p>Phone</p>
            </Link>
          </div>

          <div className="col-lg-2 col-md-4 col-sm-4 col-4 text-center">
            <Link to="/laptop" onClick={windowScroll}>
              <i className="bi bi-laptop foot-icon"></i>
              <p>Laptop</p>
            </Link>
          </div>

          <div className="col-lg-2 col-md-4 col-sm-4 col-4 text-center">
            <Link to="/tablet" onClick={windowScroll}>
              <i className="bi bi-tablet foot-icon"></i>
              <p>Tablet</p>
            </Link>
          </div>

          <div className="col-lg-2 col-md-4 col-sm-4 col-4 text-center">
            <Link to="/gadget" onClick={windowScroll}>
              <i className="bi bi-smartwatch foot-icon"></i>
              <p>Smart Watches</p>
            </Link>
          </div>

          <div className="col-lg-2 col-md-4 col-sm-4 col-4 text-center">
            <Link to="/gadget" onClick={windowScroll}>
              <i className="bi bi-boombox foot-icon"></i>
              <p>Gadgets</p>
            </Link>
          </div>

          <div className="col-lg-2 col-md-4 col-sm-4 col-4 text-center">
            <Link to="/gadget" onClick={windowScroll}>
              <i className="bi bi-speaker foot-icon"></i>
              <p>Audio</p>
            </Link>
          </div>
        </div>
      </div> */}

      <div className="container custom-container">
        <div className="row">
          <div className="col-lg-6">
            <h4 className="top-categories-heading">SHIPPING PARTNERS</h4>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <Link to="https://www.leopardscourier.com/" target="_blank">
                  <img
                    src={require("../assets/images/icons/Leopards.png")}
                    className="img-fluid"
                    alt="lep"
                  />
                </Link>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <Link to="https://www.tcsexpress.com/" target="_blank">
                  <img
                    src={require("../assets/images/icons/tcs.png")}
                    className="img-fluid"
                    alt="tcs"
                  />
                </Link>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <Link to="https://bykea.com/" target="_blank">
                  <img
                    src={require("../assets/images/icons/bykea.png")}
                    className="img-fluid"
                    alt="bykea"
                  />
                </Link>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <Link to="https://www.blue-ex.com/" target="_blank">
                  <img
                    src={require("../assets/images/icons/blueex.png")}
                    className="img-fluid"
                    alt="blueex"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <h4 className="top-categories-heading">PAYMENT PARTNERS</h4>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <Link to="/">
                  <img
                    src={require("../assets/images/icons/cod.png")}
                    className="img-fluid"
                    alt="cod"
                  />
                </Link>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <Link to="https://easypaisa.com.pk/" target="_blank">
                  <img
                    src={require("../assets/images/icons/easy-paisa.png")}
                    className="img-fluid"
                    alt="easy-paisa"
                  />
                </Link>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <Link to="https://www.jazzcash.com.pk/" target="_blank">
                  <img
                    src={require("../assets/images/icons/jazz-cash.png")}
                    className="img-fluid"
                    alt="jazz-cash"
                  />
                </Link>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <Link to="https://www.meezanbank.com/" target="_blank">
                  <img
                    src={require("../assets/images/icons/bank-transfer.png")}
                    className="img-fluid"
                    alt="bank-transfer"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="text-center">
          © 2023 Starcity.pk
          <br />
          Developed & Managed By ProfoundSolutions
        </div> */}
      </div>

      <div className="bg-black text-white py-3 text-center">
        <small>
          © 2024 Copyright www.ajhouse.com.pk | All Right Reserved
          <br />
          Design & Developed By{" "}
          <a href="https://xcretch.com/" target="_blank" rel="noreferrer">
            xcretch.com
          </a>
        </small>
      </div>
    </div>
  );
}

export default Footer;
